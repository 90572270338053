
// Body
$body-bg: #f5f8fa;

// Borders
$laravel-border-color: darken($body-bg, 10%);
$list-group-border: $laravel-border-color;
$navbar-default-border: $laravel-border-color;
$panel-default-border: $laravel-border-color;
$panel-inner-border: $laravel-border-color;

// Brands
$brand-primary: #152C37;
$brand-info: #8eb4cb;
$brand-success: #2ab27b;
$brand-warning: #cbb956;
$brand-danger: #bf5329;
$brand-grey: #EEEFF3;
$brand-light-grey: #F6F6F6; 

// Typography
$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
$font-family-sans-serif: "Lato", sans-serif;
$font-size-base: 16px;
$line-height-base: 1.6;
$text-color: #282828;
$letter-spacing: 1.12px;

// Navbar
$navbar-default-bg: #fff;

// Buttons
$btn-default-color: $text-color;

// Inputs
$input-border: lighten($text-color, 40%);
$input-border-focus: lighten($brand-primary, 25%);
$input-color-placeholder: lighten($text-color, 30%);

// Panels
$panel-default-heading-bg: #fff;

$phone-width: 320px;
$tablet-width: 768px;
$desktop-width: 1280px;
$desktop-xl-width: 1600px;

@mixin phone {
  @media (min-width: #{$phone-width}) and (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) and (max-width: #{$desktop-xl-width - 1px}) {
    @content;
  }
}

@mixin desktop-xl {
  @media (min-width: #{$desktop-xl-width}) {
    @content;
  }
}

@mixin transition($element,$time,$ease) {
    webkit-transition: $element $time $ease;
    -moz-transition: $element $time $ease;
    -o-transition: $element $time $ease;
    transition: $element $time $ease;
}
