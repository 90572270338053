.text-core {
    position: relative;
    min-width: 240px;
  }
  .text-core .text-wrap {
    background: #fff;
    position: absolute;
  }
  .text-core .text-wrap textarea,
  .text-core .text-wrap input {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 3px;
    border: 1px solid #8e8e8e;
    outline: none;
    resize: none;
    position: absolute;
    z-index: 1;
    background: none;
    overflow: hidden;
    margin: 0;
    padding: 5px 12px;
    white-space: nowrap;
    line-height: 13px;
    height: 100%;
  }

  .text-core .text-wrap .text-arrow {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    position: absolute;
    top: 0;
    right: 0;
    width: 22px;
    height: 22px;
    // background: url("arrow.png") 50% 50% no-repeat;
    cursor: pointer;
    z-index: 2;
  }
  .text-core .text-wrap .text-dropdown {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
    position: absolute;
    z-index: 3;
    background: #fff;
    border: 1px solid #8e8e8e;
    border-radius: 3px;
    width: 100%;
    max-height: 100px;
    padding: 1px;
    display: none;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .text-core .text-wrap .text-dropdown.text-position-below {
    margin-top: 1px;
  }
  .text-core .text-wrap .text-dropdown.text-position-above {
    margin-bottom: 1px;
  }
  .text-core .text-wrap .text-dropdown .text-list .text-suggestion {
    padding: 3px 5px;
    cursor: pointer;
  }
  .text-core .text-wrap .text-dropdown .text-list .text-suggestion em {
    font-style: normal;
    text-decoration: underline;
  }
  .text-core .text-wrap .text-dropdown .text-list .text-suggestion.text-selected {
    color: #fff;
    background: #6d84b4;
  }

  .text-wrap {
    height: 100%;
    width: 100%;
  }

  .text-core .text-wrap .text-clear {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    position: absolute;
    top: 0;
    right: 14px;
    width: 22px;
    height: 22px;
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAAYUlEQVR42mP4////P1IwA5RBNIBr+Pj5+/9Tlx78v373OYoCkBgIY2gACWr7tP63CO8BanoBlmyfuQssBsIYGtAVLNpwEsMADA0gAFMIw+hOpEwDSU4i2dMkByvJEUcsAABHaALCQIZDrAAAAABJRU5ErkJggg==") 50% 50% no-repeat;
    cursor: pointer;
    z-index: 2;
  }
  .text-core .text-wrap .text-focus {
    position: absolute;
    width: 100%;
    height: 100%;
    display: none;
  }
  .text-core .text-wrap .text-focus.text-show-focus {
    display: block;
  }
  
  .text-core .text-wrap .text-prompt {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    position: absolute;
    width: 100%;
    height: 100%;
    margin: 1px 0 0 2px;
    color: #999999;
    overflow: hidden;
    white-space: pre;
  }
  .text-core .text-wrap .text-prompt.text-hide-prompt {
    display: none;
  }
  
  .text-core .text-wrap .text-tags {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 3px 12px;
    cursor: text;
  }
  .text-core .text-wrap .text-tags.text-tags-on-top {
    z-index: 2;
  }
  .text-core .text-wrap .text-tags .text-tag {
    float: left;
  }
  .text-core .text-wrap .text-tags .text-tag .text-button {
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    position: relative;
    float: left;
    border: 1px solid #8e8e8e;
    background: #e2e6f0;
    color: #000;
    padding: 0px 17px 0px 3px;
    margin: 0 2px 2px 0;
    cursor: pointer;
    height: 24px;
    align-items: center;
    display: inline-flex;
  }
  .text-core .text-wrap .text-tags .text-tag .text-button a.text-remove {
    position: absolute;
    right: 3px;
    top: 2px;
    display: block;
    width: 11px;
    height: 11px;
    // background: url("close.png") 0 0 no-repeat;
  }
  .text-core .text-wrap .text-tags .text-tag .text-button a.text-remove:hover {
    background-position: 0 -11px;
  }
  .text-core .text-wrap .text-tags .text-tag .text-button a.text-remove:active {
    background-position: 0 -22px;
  }
  