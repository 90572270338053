
// Variables
@import "variables";
@import "fonts";
@import "font-awesome/font-awesome";
@import "slick";
@import "slick-theme";
@import "multiselect";
@import "animations.css";
@import "animations-ie-fix.css";
@import "tag";

$text-color: #282828;

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

$ip: "https://ashbysconsulting.co.uk/img/";

body,html {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  font-size: $font-size-base;
}

h1 {
  font-size: 2rem;
}

#app {
  position: relative;
  @include transition(all,0.5s,linear);
  left: 0vw;
  overflow: hidden;

  &.active {
    left: 70vw;
  }

}

#job-search {
  &:focus {
    border: 0;
  }
}

.job__bar {
    background: darken(white,5%);
    padding: 0.5rem 1rem;
    border-bottom: 1px solid #d9d9d9;
}

.job__list {
    background: white;
    border: 1px solid #d9d9d9;
    border-radius: 3px;
    margin-top: 1rem;
}

.parallax {
  display: inline-block;
  border: 0;
  @include transition(all,0.5s,linear);
}

.menu {
  position: fixed;
  padding: 40px;
  z-index: 20;
  background: white;
  width: 70vw;
  height: 100vh;
  top: 0;
  left: -70vw;
  @include transition(all,0.5s,linear);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-content: space-between;
  flex-direction: column;

  &.active {
    left: 0;
  }

  &__list {
    padding: 0;
    margin: 0;

    li {
      list-style: none;
      font-size: 1.25rem;
      margin-bottom: 20px;
      text-transform: uppercase;
      letter-spacing: $letter-spacing;
      color: $brand-primary;
    }

  }


  &__contact {
    margin: 0;
    padding: 0;

    li {
      list-style: none;
      font-size: 0.875rem;
      color: lighten(#000,40%);
      margin-bottom: 10px;

      i {
        font-size: 1.25rem;
      }
    }

  }

  &__social {

    i {
      display: inline-block;
      font-size: 1.5rem;
      vertical-align: middle;
      margin-right: 20px;
    }
  }

  &__contact {
    font-size: 0.75rem;
    text-transform: lowercase;
  }

  &__close {
    position: absolute;
    top: 20px;
    right: 15px;
    z-index: 20;

    i {
      color: red;
    }

  }

}

.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  padding: 40px 0 0 0;
  @include transition(all,0.5s,linear);

  @include phone {
    padding: 35px 20px 0 20px;
  }

  #Group-2 {
    fill: white;
  }

  svg {
    max-width: 160px;
    height: auto;

    @include phone {
      max-width: 120px;
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      display: none;
    }
  }

  &__logo {
    display: none;
    max-width: 160px;
    height: auto;

    @include phone {
      max-width: 120px;
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      display: block;
    }
  }

  &.fixed {
    position: fixed;
    background: rgba(256,256,256,0.85);
    padding-bottom: 30px;
  }

  &__mobile-menu {
    color: white;

    @include phone {
      padding-top: 5px;
    }

    i {
      color: white;
    }
  }

  &--page {
    // position: relative;
    // padding: 40px 0;

    #Group-2 {
      fill: $brand-primary;
    }

    i {
      color: $brand-primary;
    }

    @include phone {
      // padding: 20px 0;
    }

    .nav a {
      color: $brand-primary;
    }

  }

}

.hidden-header {
  position: fixed;
  top: -100px;
  width: 100%;
  z-index: 1000;
  opacity: 0;
  @include transition(all 0.5s linear);
  box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.05), 0 20px 40px 0 rgba(0, 0, 0, 0.03);

  @include phone {
    padding: 0;
  }

  .header__mobile-menu {
    padding-top: 0;

    i {
      color: $brand-primary;
    }

  }

  &__inset {
    padding: 20px 20px 15px 20px;
    background: rgba(256,256,256,0.96);
    width: 100%;
  }

  &.active {
    top: 0;
    opacity: 1;
  }

  .nav {
    // padding-top: 120px;
  }

  .nav a {
    color: $brand-primary;
    padding-top: 0;
    padding-bottom: 0;
  }

  svg {
    max-width: 90px;
    height: auto;
  }

}

.section {
    &__staff {
        background-color: #F5F1EC;
        padding: 2rem;

        @include phone {
            margin-top: 4rem;
            padding: 1rem;
            margin-left: -2rem;
            margin-right: -2rem;
        }

    }
}

.page-header {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 40vh;
  background: #F5F1EB;
  padding: 0;
  margin: 0;
  text-transform: uppercase;
  letter-spacing: $letter-spacing;
  color: $text-color;

  @include phone {
    height: 40vh;
    padding-top: 40px;
  }

  @include desktop {
    height: 50vh;
  }

  @include desktop-xl {
    height: 40vh;
  }

  h1 {
    margin: 0;

    @include phone {
      font-size: 1.5rem;
    }

    @include desktop {
      font-size: 2rem;
    }
  }

  &-1 {
    background: url($ip + "category-1-bg.jpg");

    @include phone {
      background: url($ip + "category-1-mobile.jpg");
    }

  }

  &-2 {
    background: url($ip + "category-2-bg.jpg");

    @include phone {
      background: url($ip + "category-2-mobile.jpg");
    }
  }

  &-3 {
    background: url($ip + "category-3-bg.jpg");

    @include phone {
      background: url($ip + "category-3-mobile.jpg");
    }
  }

  &-4 {
    background: url($ip + "category-4-strip.jpg");

    @include phone {
      background: url($ip + "category-4-mobile.jpg");
    }
  }

  &-5 {
    background: url($ip + "category-5-bg.jpg");

    @include phone {
      background: url($ip + "category-5-mobile.jpg");
    }
  }

  &-6 {
    background: url($ip + "category-6-bg.jpg");

    @include phone {
      background: url($ip + "category-6-mobile.jpg");
    }
  }

  &-1, &-2, &-3, &-4, &-5, &-6 {
    background-size: 100%;
    color: white;

    @include phone {
      background-size: cover;
    }

    @include desktop {
      background-attachment: fixed;
    }

    @include desktop-xl {
      background-attachment: fixed;
    }

  }

}

.page {
  background: white;
  padding: 60px 0;

  @include phone {
    padding: 40px 0;
  }


  &__heading {
    text-transform: uppercase;
    line-height: 1.6;
    letter-spacing: $letter-spacing;
    margin-bottom: 40px;
    font-size: 1.5rem;

    @include phone {
      font-size: 1.15rem;
    }

  }

  &__content {
    margin-bottom: 80px;
    // color: lighten($text-color,40%);
    font-family: 'Lato';
    font-size: 1.25rem;
    line-height: 1.4;
  }

}

.font-body {
    font-family: 'Lato';
    font-size: 1.25rem;
    line-height: 1.4;
}

.signup {
    background: white;
    padding: 3rem 0;
}

.about {
  background: white;

  @include phone {
    padding: 0 20px;
  }

  &__thumbnail {
      overflow: hidden;
      height: 20rem;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &__department {
      display: block;
      color: #B26800;
      margin-bottom: 1rem;
  }

  &__header {
    background: transparent;
    border-bottom: 0px;
    background: white;

    h1 {
      margin: 0;
    }
  }

  &__intro-title {
    text-transform: uppercase;
    line-height: 1.6;
    letter-spacing: $letter-spacing;
    margin-bottom: 24px;
    margin-top: 0;
    font-size: 1.5rem;

    @include phone {
      text-align: center;
    }

  }

  &__title {
    color: $text-color;
    font-style: italic;
    margin: 0;
    margin-top: 10px;
    margin-bottom: 0;
    font-family: 'Lato';
    font-size: 2rem;
    line-height: 1.4;

    &-name {
      color: $brand-primary;
      font-size: 1.25rem;
    }

  }

  &__sub-title {
    text-transform: uppercase;
    line-height: 1.6;
    letter-spacing: $letter-spacing;
    margin-bottom: 12px;
    margin-top: 8px;
    font-size: 1rem;
  }

  &__container {
    opacity: 0;
  }

  &__contact {
    margin-top: 10px;
    color: $text-color;

    @include phone {
      margin-bottom: 60px;
    }

    a {
        &:hover {
          text-decoration: underline;
        }
    }

  }

  &__contact-info {
    margin-bottom: 1rem;
    margin-top: 1rem;

    &__title {
        font-family: 'Lato';
        font-weight: 600;
        margin: 0;
        line-height: 1.44;
        margin-bottom: 4px;
        font-size: 1.25rem;
    }

    &__phone {
      font-weight: normal;
      margin: 0;
    }
  }

  &__what-they-say {
    margin-bottom: 80px;
  }

  &__slider {

    .slick-current {
      padding-right: 0;
      padding-left: 60px;

      @include phone {
        padding-left: 0;
      }

    }

    .slick-slide {
      padding-right: 60px;
      padding-left: 0;

      @include phone {
        padding-right: 0;
      }

    }

  }

}

.employers {
  background: white;
  padding-bottom: 60px;

  @include phone {
    padding: 0 20px;
  }

  &__header {
    background: white;
    border-bottom: 0px;

    @include phone {
      padding-top: 60px;
    }

    h1 {
      margin: 0;
    }

  }

  &__large-text {
    font-size: 2rem;
    text-transform: uppercase;
    line-height: 1.6;
    letter-spacing: $letter-spacing;
    margin: 0;
    display: block;

    @include phone {
      font-size: 1.15rem;
    }

  }

  &__medium-text {
    font-size: 1.5rem;
    line-height: 1.6;
    margin: 0;
    color: lighten($text-color,20%);
    display: block;

    @include phone {
      font-size: 1rem;
    }
  }

  &__small-text {
    font-size: 1.25rem;
    line-height: 1.6;
    margin: 30px 0 0 0;
    color: lighten($text-color,20%);
    display: block;

    @include phone {
      font-size: 0.87rem;
    }
  }

  &__image {
    width: 100%;
    display: inline-block;
    position: relative;
    border: 0;
    z-index: 1;

    &-bg, &-bg-2 {
      position: absolute;
      z-index: 0;
      right: -60px;
      bottom: -60px;

      @include phone {
        display: none;
      }

    }

    &-bg-2 {
      top: -60px;
      right: -60px;
    }

    &-container {
      position: relative;
      opacity: 0;
    }

  }

  &__separator {
    width: 40px;
    height: 1px;
    background: #E1E2E6;
    display: block;
    margin: 60px 0;

    @include phone {
      margin: 30px 0;
    }
  }

  &__testimonial {
    background: url($ip + "concrete.jpg");
    padding: 30px;

    @include phone {
      margin-bottom: 60px;
    }

    &-quote, &-name {
      display: block;
      color: $text-color;
    }

    &-quote {
      margin: 0;
      font-size: 1.5rem;
      margin-bottom: 30px;

      @include phone {
        font-size: 1.15rem;
      }

    }

    &-name {
      margin: 0;
      font-size: 1.25rem;

      @include phone {
        font-size: 1rem;
      }

    }

  }

}


.footer {
  padding: 60px;
  background: white;

  @include phone {
    padding: 30px 15px;
  }

  svg {
    max-width: 120px;
  }

  &__copyright, &__rights {
    font-size: 0.875rem;
    margin: 0;
    color: lighten($text-color,30%);
  }

  &__list {
    margin: 30px 0 0 0;
    padding: 0;
    font-size: 0.875rem;
    color: lighten($text-color,30%);

    @include phone {
      margin: 15px 0 0 0;
    }

    li {
      list-style: none;
      margin-bottom: 5px;

      a {
        color: lighten($text-color,30%);
        @include transition(all 0.5s linear);

        &:hover {
          color: lighten($text-color,10%);
        }

      }

      i {
        display: inline-block;
        font-size: 0.875rem;
        vertical-align: middle;
        margin-right: 10px;
        color: lighten($text-color,10%);
      }

    }

  }

  h4 {
    margin: 0;

    @include phone {
      margin-top: 4rem;
    }

  }

}

.nav {

  a {
      color: white;
  }

}

.navbar-nav {
  @include transition(all 0.5s linear);
  z-index: 999;

  li {
      font-size: 18px;
  }

  &:hover > li {
    opacity: 0.3;

    &:hover {
      opacity: 1;
    }

  }

  & > li {
    @include transition(all 0.5s linear);

    & > a {
      padding: 10px 30px;
      @include transition(all 0.5s linear);
      z-index: 999;
      font-size: 1.25rem;
      font-family: 'Lato';

      &:hover {
        background: transparent;
      }

    }

    &:last-child {

      a {
        padding-right: 0px;
      }

    }

  }

}

.navbar-right {
  margin-right: 0px !important;
}

.homepage {
  display: block;

  &__hero {
    // background: #6AA0CE url($ip + "home-bg.jpg") center center no-repeat;
    // background-size: cover;
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;

    @include phone {
        padding: 0 20px;
        height: 70vh;
    }

    @include tablet {
        height: 60vh;
    }

    @include desktop {
        // background: #6AA0CE url($ip + "home-bg-1280.jpg") center center no-repeat;
        // // background-position: center -40px;
        // background-size: cover;
        height: 90vh;
    }

    // @include desktop-xl {
    //     background: #6AA0CE url($ip + "home-bg.jpg") center center no-repeat;
    //     background-position: center -170px;
    //     background-size: cover;
    //     height: 90vh;
    // }

    &-title {
      margin: -200px 0 0 0;
      color: white;
      letter-spacing: $letter-spacing;
      text-transform: uppercase;
      text-align: center;
      line-height: $line-height-base;
      font-size: 2.5rem;
      opacity: 0;

      @include phone {
          margin: -60px 0 0 0;
          font-size: 1.25rem;
      }

      @include tablet {
          margin: -80px 0 0 0;
          font-size: 1.5rem;
      }

      @include desktop {
          margin: -100px 0 0 0;
          font-size: 2rem;
      }

      @include desktop-xl {
          font-size: 2.5rem;
      }

    }

    &-container {
      position: relative;
    }

  // &__hero {
  //   background: #6AA0CE url($ip + "bg-front-june-3.jpg") center center no-repeat;
  //   background-size: cover;
  //   height: 90vh;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   align-content: center;
  //
  //   @include phone {
  //       background: #6AA0CE url($ip + "bg-mobile-june-3.jpg") bottom center no-repeat;
  //       background-size: 100%;
  //       padding: 0 20px;
  //       height: 70vh;
  //   }
  //
  //   @include tablet {
  //       height: 60vh;
  //   }
  //
  //   @include desktop {
  //       background: #6AA0CE url($ip + "bg-front-june-3.jpg") center top no-repeat;
  //       background-size: cover;
  //       height: 100vh;
  //   }
  //
  //   @include desktop-xl {
  //       background: #6AA0CE url($ip + "bg-front-june-3.jpg") center center no-repeat;
  //       background-size: cover;
  //       height: 90vh;
  //   }
  //
  //   &-title {
  //     margin: -200px 0 0 0;
  //     color: white;
  //     letter-spacing: $letter-spacing;
  //     text-transform: uppercase;
  //     text-align: center;
  //     line-height: $line-height-base;
  //     font-size: 2.5rem;
  //     opacity: 0;
  //
  //     @include phone {
  //         margin: -60px 0 0 0;
  //         font-size: 1.25rem;
  //     }
  //
  //     @include tablet {
  //         margin: -80px 0 0 0;
  //         font-size: 1.5rem;
  //     }
  //
  //     @include desktop {
  //         margin: -100px 0 0 0;
  //         font-size: 2rem;
  //     }
  //
  //     @include desktop-xl {
  //         font-size: 2.5rem;
  //     }
  //
  //   }
  //
  //   &-container {
  //     position: relative;
  //
  //     @include desktop {
  //         transform: translateY(-30px);
  //     }
  //
  //
  //   }
  //
  }

  &__video {
    width: 100vw;
    height: 100vh;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;

    div, video {
      position: absolute !important;
    }

  }

}

#filter-jobs {
    padding-top: 1rem;
    background-color: white;
}

#location-input {

}

.search {
  position: absolute;
  margin-top: 40px;
  width: 100%;
  opacity: 0;
  @include transition(all 0.5s linear);

  &__input {
    position: relative;

    &-icon {
      position: absolute;
      top: 0;
      right: 0;
      min-height: 55px;
      min-width: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;

      @include desktop {
        min-height: 50px;
      }

      @include desktop-xl {
        min-height: 50px;
      }

    }

    &-search {
      display: inline-block;
      vertical-align: middle;

      @include phone {
        margin-bottom: -5px;
      }

    }

    &-spinner {
      display: none;
      @include transition(all 0.5s linear);
    }

  }



  &__results {
    background: rgba(256,256,256,0.97);
    margin: 0;
    padding: 0;
    border-radius: 0 0 4px 4px;
    max-height: 250px;
    overflow-x: auto;
    display: none;

    li {
      padding: 20px;
      border-top: 1px solid darken($brand-grey,5%);
      list-style: none;
      @include transition(all 0.5s linear);

      @include phone {
        padding: 10px 20px;
      }

      &.string {
        padding: 10px 20px;
        background-color: $brand-grey;
      }

      a {

        // &:hover {
        //   text-decoration: none;
        // }

      }

      &.string {

        a {
          text-decoration: underline;
          margin-left: 10px;
        }

        &:hover {
          background: transparent;
        }
      }

      &:hover {
        background: darken(white,7.5%);
      }

    }

  }

}

.jobs {
  list-style: none;
  margin: 0;
  padding: 0;
  @include transition(all 0.5s linear);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  &:after {
    clear: both;
    display: inline-table;
    content: "";
  }

  li {
    width: 50%;
    flex-basis: 50%;
    float: left;
    padding: 2rem;
    border-bottom: 1px solid #d9d9d9;

    &:nth-child(even) {
        border-left: 1px solid #d9d9d9;
    }

    @include phone {
      width: 100%;
      flex-basis: 100%;
    }

  }

  &--list {
      li {
        width: 100%;
        flex-basis: 100%;
        padding-right: 0;
        padding-left: 0;
        border-left: 0;
        border-right: 0;

        &:nth-child(even) {
            border-left: 0;
        }
      }
  }

  &__loading {
      min-height: 400px;
      display: flex;
      padding: 3rem;
      align-items: center;
      align-content: center;
      justify-content: center;
      font-size: 2rem;
  }

//   &:hover > li {
//     opacity: 0.5;
//     // filter: blur(1px);

//     &:hover {
//       opacity: 1;
//       // filter: blur(0px);
//     }

//   }

  &--full-width {

    li {
      width: 100%;
      flex-basis: 100%;

      &:nth-child(odd) {
        padding-right: 0;
      }

      &:nth-child(even) {
        padding-left: 0;
      }

    }

  }
}

.job {
  position: relative;
  display: block;
  @include transition(all,0.5s,linear);

  &:hover {

    .job__title {
        text-decoration: underline;
    }

    // a {
    //   text-decoration: none;
    // }

  }

  &__inset {

  }

  &__title {
    font-size: 1.25rem;
    line-height: $line-height-base;
    color: $text-color;
    // min-height: 60px;

    @include phone {
      font-size: 1.15rem;
      text-align: left;
      min-height: auto;
    }

    @include tablet {
      font-size: 1.15rem;
    }


    &--salary {
      min-height: auto;

      @include phone {
        margin-top: 10px;
      }

    }

    &--search {
      @include phone {
        font-size: 1rem;
      }

      @include desktop {
        min-height: auto;
      }

      @include desktop-xl {
        min-height: auto;
      }

    }

  }

  &__salary-description {
    margin: 15px 0 0 0;
    font-size: 0.9rem;
    color: lighten($text-color,40%);
    text-align: right;

    a {
      color: lighten($brand-primary,20%);
    }

    @include phone {
      font-size: 0.875rem;
      margin: 5px 0 0 0;
      text-align: left;
    }
  }

  &__info {
    margin-top: 10px;
    font-size: 1rem;
    color: lighten($text-color, 30%);

    &:after {
      content: "";
      clear: both;
      display: inline-table;
    }

    &__profile {
        display: flex;
        align-items: center;

        @include phone {
           flex-direction: column;
        }

        &__title {
            font-family: 'Lato';
            font-size: 1.5rem;
            margin: 0 0 1rem 0;
            color: $text-color;
            line-height: 140%;
        }

        &__cta {

        }
    }

    @include phone {
      margin-top: 15px;
    }

    span, i {
      display: inline-block;
      vertical-align: middle;
    }

    i {
      font-size: 1rem;
      margin-right: 5px;

      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        display: none;
      }

      @include phone {
        font-size: 0.9rem;
      }
    }

    span {
      margin-right: 30px;

      @include phone {
        margin-right: 10px;
        display: block;
      }
    }

    a {
      color: lighten($text-color, 30%);
      text-decoration: underline;
    }


    &--single {
      font-size: 1rem;
      margin: 0;
      display: flex;
      flex-direction: column;

      &__item {
        margin-bottom: 1rem;
      }

      &__video {
        color: #B26800 !important;
      }

      &__content {
        margin-left: 1rem;
        display: flex;
        justify-items: center;
        flex-direction: column;
        padding: 1rem;

        @include phone {
           padding: 1rem;
           margin-left: 0;
        }
      }

      &__profile {
        height: 170px;
        width: 140px;
        object-fit: cover;
        object-position: center;

        @include phone {
            width: 100%;
         }

      }

      @include phone {
        text-align: left;
        margin: 0 0 30px 0;
      }
    }

    &--action {
        display: flex;
        flex-direction: column;
        background-color: #F5F1EB;
        width: 100%;
        margin-bottom: 2rem;

        a {
            margin-right: 1rem;

            @include phone {
                display: block;
            }
        }
    }

    &--search {

      @include phone {
        margin-top: 5px;
        margin-bottom: 5px;
      }

      span {

        @include phone {
          font-size: 0.75rem;
          margin-right: 10px;
          display: inline-block;
        }
      }

    }

    &--register {
      font-size: 1rem;
      margin: 0;

      span {
        margin: 0;
        text-align: right;
      }

    }

  }

  &__description {
    margin-top: 10px;
    font-size: 0.9rem;
    color: lighten($text-color,40%);

    @include phone {
      margin-top: 15px;
    }

  }

  &__cta {
    margin-top: 20px;
  }

  &-scroll {
  	overflow-x: scroll;
  	-webkit-overflow-scrolling: touch;
    width: 100%;
    overflow-y: hidden;
    white-space: nowrap;
    position: relative;
    display: block;
    padding-left: 20px;

    ul {
      padding: 0;
      margin: 0;
    }

    &__item {
      position: relative;
      display: inline-block;
      margin-right: 20px;
      max-width: 250px;
      white-space: normal;
    }

  }

}

.job-categories, .inspire-me, .what-say, .job-alert, .pompus, .apply-now {
  padding: 80px 0;
  @include transition(all 0.5s linear);

  @include phone {
    padding: 40px 0;
  }

  &__title {
    line-height: 1;
    font-size: 1.5rem;
    letter-spacing: $letter-spacing;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 80px;

    @include phone {
      font-size: 1.25rem;
      margin-bottom: 40px;
    }

  }

}


#category-main {
  min-height: 288px;
}

.job-categories {
  background: $brand-primary;

  &__title {
    color: white;
  }

  &__category {
    position: relative;
    box-shadow: 0 6px 6px 0 rgba(0,0,0,0.26), 0 10px 20px 0 rgba(0,0,0,0.19);
    margin-bottom: 10px;
    opacity: 0;
    background: url("https://ashbysconsulting.co.uk/img/concrete.jpg") fixed;
    @include transition(all 0.5s linear);

    @include phone {
      opacity: 1;
    }

    &:hover {
      box-shadow: 0 15px 12px 0 rgba(0,0,0,0.22), 0 19px 38px 0 rgba(0,0,0,0.30);
      cursor: pointer;

      &:after {
        background: rgba(23,44,55,0.5);
      }

    }

    @include phone {

      &:hover {
        box-shadow: none;

        &:after {
          background: transparent;
        }

      }

    }


    &-title {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 2;
      display: flex;
      align-items: center;
      justify-content: center;
      align-content: center;
      letter-spacing: $letter-spacing;
      text-transform: uppercase;
      text-align: center;

      h4 {
        color: white;
        font-size: 1.25rem;
        line-height: $line-height-base;
        margin-left: 12px;
        margin-right: 12px;

        @include phone {
          font-size: 1rem;
          margin: 0 10px;
        }
      }

    }

    &:after {
      position: absolute;
      height: 100%;
      width: 100%;
      content: "";
      z-index: 1;
      background: rgba(23,44,55,0.7);
      @include transition(all 0.5s linear);
      top: 0;
      left: 0;
    }

    &-image {
      width: 100%;
      display: block;
      border: 0;
    }

  }

  .row {
    margin-left: -5px;
    margin-right: -5px;
  }

  .col-xs-12, .col-sm-6, .col-md-6, .col-lg-4 {
    padding-left: 5px;
    padding-right: 5px;
  }

}

.job-filter-by {
  padding: 20px 0 20px 0;
  background: white;
  border-bottom: 1px solid #dedede;

  &__label {
    text-align: center;
    font-weight: bold;
  }

  @include phone {
    border-bottom: 1px solid darken(white,10%);
    margin-bottom: 20px;
  }

  &__row {
    display: flex;
    justify-content: center;
    align-content: center;

    @include phone {
      flex-direction: column;
    }
  }

  &__col {
    margin-right: 1rem;

    @include phone {
      margin-bottom: 0.5rem;
    }
  }

  &__separator {
    border-left: 2px solid #dedede;
    height: auto;
    margin: 0 1rem 0 0;
  }

  input {
    display: inline-block;
  }

}

.inspire-me {
  background: white;

  &__title {
    color: $brand-primary;
  }

}

.what-say {
  background: $brand-light-grey;

  @include phone {
    padding: 0 20px;
  }

  &__title {
    color: $brand-primary;
  }

  &__item {
      display: block;
      margin-bottom: 3rem;
  }

  &__name {
    font-size: 1.15rem;
    line-height: $line-height-base;
    // color: lighten($text-colour, 20%);
    color: #6f6f6f;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-top: 0;

    @include phone {
      font-size: 1rem;
    }
  }

  &__description {
    font-size: 1.75rem;
    margin: 0;
    font-family: 'Lato';
    font-style: italic;
    font-weight: 600;
    margin-bottom: 16px;

    @include phone {
      font-size: 1.5rem;
    }

  }

}

.v3 {
    &-font-crimson {
        font-family: 'Lato';
    }
}

.job-alert {
  //background: url($ip + "concrete.jpg");
  background: #EDF1F6;
  background-size: cover;

  // @include phone {
  //   margin: 0 -20px;
  // }

  &__title {
    color: $brand-primary;
  }

  &__box {
    background: white;
    box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.05), 0 20px 40px 0 rgba(0, 0, 0, 0.03);
    padding: 30px;
    opacity: 0;
    border-radius: 4px;
    @include transition(all 0.5s linear);

    @include phone {
      padding: 0 20px;
      box-shadow: none;
      background: transparent;
    }

    p {
      margin-bottom: 15px;
    }

    .row {
      margin-left: -7.5px;
      margin-right: -7.5px;
    }

    .col-md-6, .col-md-12, .col-md-4, .col-md-8, .col-md-5, .col-md-7 {
      padding-left: 7.5px;
      padding-right: 7.5px;
    }

    // .alert-danger {
    //   display: none;
    //   margin-top: 15px;
    // }

    &:hover {
      box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.10), 0 20px 40px 0 rgba(0, 0, 0, 0.06);

      @include phone {
        box-shadow: none;
      }
    }

  }

  &__categories {
    display: flex;
    margin: 0 0 20px 0;
    padding: 0;
    flex-wrap: wrap;

    li {
      background: white;
      width: 50%;
      flex-basis: 50%;
      list-style: none;
      padding: 10px 20px;
      border-bottom: 2px solid $brand-light-grey;
      display: inline-block;
      vertical-align: middle;
      min-height: 54px;
      color: lighten($text-color,30%);
      @include transition(all 0.5s linear);

      i {
        display: inline-block;
        font-size: 1.25rem;
        margin-right: 10px;
      }

      span {
        vertical-align: middle;
      }

      &:nth-child(even) {
        border-left: 2px solid $brand-light-grey;
      }

      @include phone {
        width: 100%;
        flex-basis: 100%;
        min-height: 44px;
        font-size: 0.875rem;

        &:nth-child(even) {
          border-left: none;
        }
      }

      &:hover {
        background: darken(white,2%);
        cursor: pointer;
        color: $text-color !important;
      }

    }

    &-outline {
      i {
        font-size: 1.27rem;
      }
    }

    &-check {
      display: none;
    }

    &--active {
      color: $text-color !important;

      .job-alert__categories-outline {
        display: none;
      }

      .job-alert__categories-check {
        display: inline-block;
      }

    }

    &.error {
      border: 1px solid #FD9191;
    }

    &--not-active {  }

  }

  &__state {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 500;

    &-container {
      justify-content: center;
      align-items: center;
      align-content: center;
      display: flex;
      height: 100%;
      text-align: center;
    }

    &-text {
      color: lighten($text-color,40%);
      margin-bottom: 30px;
    }


  }

}

#job-alert-loading, #job-alert-success, #apply-now-loading, #apply-now-success, #register-interest-loading, #register-interest-success, #apply-now-confirm  {
  display: none;
  padding: 15px;

  @include phone {
    background: white;
    padding: 30px;
  }

}

//#job-alert-success
#job-alert-success, #apply-now-success, #register-interest-success {

  h3 {
    margin: 0 0 15px 0;

    @include phone {
      margin: 0 0 15px 0;
    }

  }

  i {
    font-size: 3rem;
    color: #66BB6A;
  }
}

#register-interest-success {
  h3 {
    margin: 0 0 30px 0;

    @include phone {
      margin: 0 0 15px 0;
    }

  }
}

#register-interest-form {
//   .alert-danger {
//     display: none;
//     margin-top: 15px;
//   }
}

.job-single {
  background: white;
  padding: 180px 0 60px 0;

  @include phone {
    padding: 100px 20px 60px 20px;
  }

  &__title {
    margin: 0;
    font-size: 1.75rem;
    line-height: $line-height-base;

    @include phone {
      font-size: 1.15rem;
    }

    &--salary {
      text-align: right;

      @include phone {
        text-align: left;
      }

    }

  }

  &__sub-title {
    margin: 0 0 0 0;
    font-size: 1rem;
    color: lighten($text-color,40%);
    text-align: right;

    a {
      color: lighten($brand-primary,20%);
    }

    @include phone {
      font-size: 0.875rem;
      margin: 5px 0 0 0;
      text-align: left;
    }

  }

  &__separator {
    width: 40px;
    height: 1px;
    background: #E1E2E6;
    display: block;
    margin: 30px 0;

    &--right {
      float: right;
    }

    &--large {
      margin: 60px 0;

      @include phone {
        margin: 30px 0;
      }

    }

  }

  &__cta {
    margin-top: 30px;

    &-fixed {
      position: fixed;
      padding: 20px;
      bottom: 0;
      background: rgba(256,256,256,0.9);
      z-index: 10;
      width: 100%;
      @include transition(all 0.5s linear);
      display: none;

      @include phone {
        display: block;
      }

    }

  }

  &__small-text {
    font-size: 1rem;
    line-height: 1.6;
    margin: 0;
    color: lighten($text-color,20%);
    display: block;
  }

  &__share-links {
    display: flex;
    margin-bottom: 30px;
    justify-content: center;
    align-content: center;
    align-items: center;

    i {
      font-size: 3rem;
      padding: 0 15px;
    }

    .fa-facebook {
      color: #3B5998;
    }

    .fa-twitter {
      color: #00ACED;
    }

    .fa-linkedin {
      color: #007BB6;
    }

    .fa-whatsapp {

    }

  }

  .btn-whatsapp {
    margin-top: 15px;
  }

}

.receive-alerts {
  display: flex;
  align-items: center;
  align-content: center;
  padding-top: 10px;

  @include phone {
    padding-top: 30px;
    justify-content: center;
    padding-bottom: 30px;
  }

  &:hover {
    cursor: pointer;
  }

  &__title, &__sub-title {
    margin: 0;
    display: block;
    vertical-align: bottom;
    line-height: 1;
  }

  &__title {
    color: $text-color;
    margin-bottom: 5px;

    @include phone {
      color: white;
    }

  }

  &__sub-title {
    color: lighten($text-color,40%);
    font-size: 0.875rem;

    @include phone {
      display: none;
    }

  }

  .receive-alerts-checkbox {
    display: inline-block;
    line-height: 1;
    vertical-align: bottom;
    @include transition(all 0.5s linear);

    i {
      font-size: 1.15rem;
      margin-right: 10px;

      @include phone {
        color: white;
      }

    }

    &:hover {
      opacity: 1;
      cursor: pointer;
    }

    .receive-alerts-outline {
      display: inline-block;
      vertical-align: bottom;
      line-height: 1;
    }

    .receive-alerts-check {
      display: none;
      line-height: 1;
      vertical-align: bottom;
    }

    &.active {

      i {
        color: $brand-primary;

        @include phone {
          color: white;
        }

      }

      .receive-alerts-outline {
        display: none;
      }

      .receive-alerts-check {
        display: inline-block;
      }

    }

  }

}

.register {

  &__cta {
    margin-bottom: 60px;

    &-title {
      font-size: 1.25rem;
      color: lighten($text-color,20%);
    }

    &-link {
      color: lighten($text-color,30%);
      text-decoration: underline;
    }

  }
}

.pompus {
  background: url($ip + "pompus-2.jpg") fixed center center;
  background-size: cover;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;

  @include phone {
    height: 50vh;
    padding-left: 20px;
    padding-right: 20px;
  }

  &__title {
    margin-bottom: 40px;
    line-height: 1.6;
    font-size: 2rem;
    color: $brand-primary;

    @include phone {
      font-size: 1.15rem;
    }

  }

  &__container {
    opacity: 0;
  }

}

.apply-now {
  background: $brand-primary;

  &__title {
    color: white;
  }

  &.category {

    &-1 {
      background: url($ip + "category-1-bg.jpg");

      @include phone {
        background: url($ip + "category-1-mobile.jpg");
      }
    }

    &-2 {
      background: url($ip + "category-2-bg.jpg");

      @include phone {
        background: url($ip + "category-2-mobile.jpg");
      }

    }

    &-3 {
      background: url($ip + "category-3-bg.jpg");

      @include phone {
        background: url($ip + "category-3-mobile.jpg");
      }

    }

    &-4 {
      background: url($ip + "category-4-bg.jpg");

      @include phone {
        background: url($ip + "category-4-mobile.jpg");
      }

    }

    &-5 {
      background: url($ip + "category-5-bg.jpg");

      @include phone {
        background: url($ip + "category-5-mobile.jpg");
      }

    }

    &-6 {
      background: url($ip + "category-6-bg.jpg");

      @include phone {
        background: url($ip + "category-6-mobile.jpg");
      }

    }

    &-1, &-2, &-3, &-4, &-5, &-6 {
      background-size: cover;

      @include desktop {
        background-attachment: fixed;
      }

      @include desktop {
        background-attachment: fixed;
      }

      @include desktop-xl {
        background-attachment: fixed;
      }

    }

  }

}

.work {
  background: darken(white,2%);
  padding: 60px 0;

  @include phone {
    padding: 60px 20px;
  }

  i {
    font-size: 1.5rem;
    color: $brand-primary;

    @include phone {
      font-size: 1.25rem;
    }
  }

  &__header {
    background: url("https://ashbysconsulting.co.uk/img/work@1400.jpg") center center;
    background-size: cover;
    height: 85vh;

    h1 {
      color: white;
      transform: translateY(-1rem);

      @include phone {
        transform: translateY(-2rem);
      }
    }
  }

  &__title {
    position: relative;
    display: block;
    margin: 0 0 4rem 0;
    text-transform: uppercase;
    font-size: 1.5rem;
    line-height: 1.5;
  }

  &__separator {
    width: 40px;
    height: 1px;
    background: #E1E2E6;
    display: block;
    margin: 4rem 0;
  }

  &__item {
    position: relative;
    display: block;
    margin: 0 0 3rem 0;

    h5 {
      font-size: 1.25rem;
      line-height: 1.5;
    }
  }

}

.contact {
  background: white;
  padding: 60px 0;

  @include phone {
    padding: 60px 20px;
  }

  &__header {
    background: transparent;
    color: white;
    border: none;
    height: 60vh;

    @include phone {
        height: 40vh;
      }

      @include desktop {
        height: 50vh;
      }

      @include desktop-xl {
        height: 60vh;
      }
  }

  &__title {
    text-transform: uppercase;
    letter-spacing: $letter-spacing;
    color: $text-color;
    margin-bottom: 30px;
  }

  &__small-text {
    font-size: 1rem;
    line-height: 1.6;
    margin: 0;
    color: lighten($text-color,20%);
    display: block;
    margin-bottom: 10px;
  }

  &__separator {
    width: 40px;
    height: 1px;
    background: #E1E2E6;
    display: block;
    margin: 60px 0;
  }

  &__video {
    width: 100vw;
    height: 50vh;
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;

    div, video {
      position: fixed !important;
    }

  }

}

.file-dropzone {
  padding: 20px;
  background: transparent;
  text-align: center;
  border: 2px dashed darken(white,10%);
  margin-bottom: 15px;

  &__title {
    text-transform: uppercase;
    letter-spacing: $letter-spacing;
    color: $brand-primary;
    font-size: 1.25rem;
    margin: 0;
  }

  &__sub-title {
    font-size: 1rem;
    color: $text-color;
  }

}

// Globals

.tac {
  text-align: center;
}

.tar {
  text-align: right;
}

.btn {
  padding: 15px 30px;
  border-radius: 2px;
  text-transform: uppercase;
  letter-spacing: $letter-spacing;
  font-weight: bold;
  @include transition(all 0.5s linear);

  @include phone {
    width: 100%;
    padding: 10px 20px;
  }

  i {
    margin-right: 10px;
  }

  &-ghost {
    border: 2px solid lighten($text-color, 70%);
    color: $brand-primary;
    background: transparent;

    &:hover {
      border: 2px solid $brand-primary;
      color: $brand-primary;
    }

  }

  &-white {
      background: white;
      color: $brand-primary;

      &:hover {
        background: darken(white,10%);
      }
  }

  &-whatsapp {
    border: 2px solid #C7F2C1;
    color: #34AF23;
    background: transparent;

    &:visited, &:active, &:focus {
      border: 2px solid darken(#C7F2C1,10%);
      color: #34AF23;
    }

  }

  &-dropdown {
    border: none;
    background-color: transparent;
    padding: 0;
    text-transform: none;
    color: lighten($text-color,20%);
    font-weight: normal;
    letter-spacing: normal;
    vertical-align: top;

    i {
      font-size: 16px;
      display: inline-block;
      vertical-align: middle;
      margin-right: 5px;
    }

  }

  &-slim {
    padding: 5px 8px;
    font-size: 12px;
  }

}

.form-control {
  position: relative;
  font-size: 1rem;
  padding: 12px 20px;
  border-radius: 4px;
  color: $text-color;
  // border: none;
  height: 50px;
  box-shadow: none;
  display: inline-flex;
  align-items: center;

  &:focus {
    box-shadow: none;
    outline: 0;
  }

  &.results {
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  @include phone {
    padding: 0 15px;
  }

  &.error {
    border: 1px solid #FD9191;
  }

  &.salary {
    &:before {
      content: "£";
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &.location {
    font-family: 'Material Icons';
    content: "\E55F";
    position: absolute;
    top: 0;
    left: 0;
  }

  &-filter {
    padding: 0;
    min-height: 0;
  }

  &--slim {
    padding: 0 8px;
    height: 30px;
  }

  &--semi-opaque {
    opacity: 0.96;
  }

  &--select {
    min-width: 120px;
  }

}

.modal-open .modal {
  background: RGBA(21, 44, 55,0.8);
}

.modal {

  @include phone {
    width: 100vw;
  }

  &-header {
    color: white;
    letter-spacing: $letter-spacing;
    text-transform: uppercase;
    text-align: center;
    line-height: $line-height-base;
    font-size: 2.5rem;
    padding: 0 0 30px 0;
    border: none;
  }

  &-title {
    line-height: 1;
  }

  &-dialog {

    @include phone {
      margin: 35px;
    }

    @include desktop-xl {
      margin: 120px auto;
    }

  }

  &-content {
    background: transparent;
    box-shadow: none;
    border-radius: 0px;
    border: none;
  }

  &-body {
    background: url($ip + "concrete.jpg");
    border-radius: 2px;
    padding: 30px;

    @include phone {
      padding: 15px;
    }

  }

}

.empty-state {
  text-align: center;
  width: 50vw;
  margin: 0 auto;
  display: block;
  background: darken(white,3%);
  padding: 30px;

  @include phone {
    margin-top: 30px;
  }

  &__title {
    font-size: 2rem;
    line-height: $line-height-base;

    @include phone {
      font-size: 1.25rem;
    }
  }

  &__copy {
    color: lighten($text-color,30%);
  }

  @include phone {
    width: 100%;
  }
}

.close {
  text-shadow: none;
  opacity: 1;
  color: white;
}

.input-group-addon {
  background-color: $brand-grey;
  border: none;
}

.shadow-shallow {
  box-shadow: 0 6px 6px 0 rgba(0,0,0,0.26), 0 10px 20px 0 rgba(0,0,0,0.19);
}

.shadow-deep {
  box-shadow: 0 15px 12px 0 rgba(0,0,0,0.22), 0 19px 38px 0 rgba(0,0,0,0.30);
}

$offset: 187;
$duration: 1.4s;

.spinner {
  animation: rotator $duration linear infinite;
  height: 16px;

  &--large {
    height: 64px;
  }

}

@keyframes rotator {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(270deg); }
}

.path {
  stroke-dasharray: $offset;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation:
    dash $duration ease-in-out infinite,
    colors ($duration*4) ease-in-out infinite;
}

@keyframes colors {
	0% { stroke: $brand-grey; }
	25% { stroke: lighten($text-color,30%); }
	50% { stroke: $brand-grey; }
	75% { stroke: lighten($text-color,50%); }
  100% { stroke: $brand-grey; }
}

@keyframes dash {
 0% { stroke-dashoffset: $offset; }
 50% {
   stroke-dashoffset: $offset/4;
   transform:rotate(135deg);
 }
 100% {
   stroke-dashoffset: $offset;
   transform:rotate(450deg);
 }
}

.come-in {
  transform: translateY(150px);
  animation: come-in 0.8s ease forwards;
}
.come-in:nth-child(odd) {
  animation-duration: 0.6s; /* So they look staggered */
}

.fade-in {
  opacity: 1;
}

.fade-out {
//   opacity: 0;
}

@keyframes come-in {
  to { transform: translateY(0); }
}

.form-check-input.error + label{
  color: red;

  a {
    color: red;
  }
}

label {
  a {
    text-decoration: underline;
  }
}


.alert {
  position: relative;
  width: 100%;
}

#apply-now-errors {
  position: relative;
}


.dropdown {
  position: absolute;
  top: 100%;
  margin: 0;
  padding: 0;
  background: white;
  min-width: 200px;
  max-height: 300px;
  overflow-x: auto;
  left: 0;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  box-shadow: 0 6px 6px 0 rgba(0,0,0,0.26), 0 10px 20px 0 rgba(0,0,0,0.19);
  display: none;
  z-index: 10;

  &-text {
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
  }

  &:hover {
    cursor: pointer;
  }

  li {
    list-style: none;
    padding: 0.5rem 1rem;
    border-bottom: 1px solid #D9d9d9;
    display: flex;
    align-items: center;

    &:hover {
      background-color: darken(white,5%);
    }

    &.selected {
      background-color: darken(white,5%);
    }

    input {
      margin: 0;
      margin-right: 0.5rem;
    }
  }

  label {
    font-weight: normal;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
  }

}


.category-selector {
    display: flex;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    margin-bottom: 1rem;

    &__main {
        flex-direction: column;
        margin: 0;
        padding: 0;
        width: 100%;
        text-transform: capitalize;

        li {
            background: white;
            min-height: 44px;
            width: 100%;
            border-bottom: 1px solid #d9d9d9;
            display: flex;
            align-items: center;
            list-style: none;
            padding: 0 1rem;
            cursor: pointer;
        }
    }

    &__toggle {
        position: relative;

        &:after {
            content: "";
            background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMThweCIgaGVpZ2h0PSI5cHgiIHZpZXdCb3g9IjAgMCAxOCA5IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA1OS4xICg4NjE0NCkgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+YXJyb3ctZG93bjwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJNb2JpbGUvRGVza3RvcCIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIj4KICAgICAgICA8ZyBpZD0iUFAtLy1kZXNrdG9wIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTMzNS4wMDAwMDAsIC0xMjQ2LjAwMDAwMCkiIHN0cm9rZT0iIzAwMDAwMCI+CiAgICAgICAgICAgIDxnIGlkPSJhcnJvdy1kb3duIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMzM2LjAwMDAwMCwgMTI0Ni4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0xNS42NjY2NjY3LDAuNDcxMzMzMzMzIEw4LjQ3MTMzMzMzLDcuNjY2NjY2NjcgQzguMjExMDAwMSw3LjkyNjkyMTI5IDcuNzg4OTk5OSw3LjkyNjkyMTI5IDcuNTI4NjY2NjcsNy42NjY2NjY2NyBMMC4zMzMzMzMzMzMsMC40NzEzMzMzMzMiIGlkPSJQYXRoIj48L3BhdGg+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==");
            position: absolute;
            background-size: 1.5rem;
            background-repeat: no-repeat;
            background-position: center center;
            height: 100%;
            width: 2rem;
            right: 1rem;
            display: flex;
            z-index: 10;
            align-items: center;
        }

        &.selected {
            &:after {
                background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMThweCIgaGVpZ2h0PSI5cHgiIHZpZXdCb3g9IjAgMCAxOCA5IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA1OS4xICg4NjE0NCkgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+YXJyb3ctdXA8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iTW9iaWxlL0Rlc2t0b3AiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCI+CiAgICAgICAgPGcgaWQ9IlBQLS8tZGVza3RvcCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTEzMDAuMDAwMDAwLCAtMTI0NS4wMDAwMDApIiBzdHJva2U9IiMwMDAwMDAiPgogICAgICAgICAgICA8ZyBpZD0iYXJyb3ctdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEzMDkuMDAwMDAwLCAxMjUwLjAwMDAwMCkgc2NhbGUoMSwgLTEpIHRyYW5zbGF0ZSgtMTMwOS4wMDAwMDAsIC0xMjUwLjAwMDAwMCkgdHJhbnNsYXRlKDEzMDEuMDAwMDAwLCAxMjQ2LjAwMDAwMCkiPgogICAgICAgICAgICAgICAgPHBhdGggZD0iTTE1LjY2NjY2NjcsMC40NzEzMzMzMzMgTDguNDcxMzMzMzMsNy42NjY2NjY2NyBDOC4yMTEwMDAxLDcuOTI2OTIxMjkgNy43ODg5OTk5LDcuOTI2OTIxMjkgNy41Mjg2NjY2Nyw3LjY2NjY2NjY3IEwwLjMzMzMzMzMzMywwLjQ3MTMzMzMzMyIgaWQ9IlBhdGgiPjwvcGF0aD4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+");
            }
        }
    }

    &__subs, &__single {
        margin: 0;
        padding: 0;
        opacity: 0;
        max-height: 0px;
        display: block;
        @include transition(all 0.45s ease-in-out);

        li {
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: center;
            background: white;

            &:hover {
                background: darken(white,4%);
                cursor: pointer;
            }

            &.selected {
                &:after {
                    content: "";
                    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTZweCIgaGVpZ2h0PSIxNnB4IiB2aWV3Qm94PSIwIDAgMTYgMTYiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU5LjEgKDg2MTQ0KSAtIGh0dHBzOi8vc2tldGNoLmNvbSAtLT4KICAgIDx0aXRsZT5jaGVjay1jaXJjbGUtMTwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJNb2JpbGUvRGVza3RvcCIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9IlBQLS8tZGVza3RvcCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTEzNjEuMDAwMDAwLCAtMTI0Mi4wMDAwMDApIj4KICAgICAgICAgICAgPGcgaWQ9ImNoZWNrLWNpcmNsZS0xIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMzYxLjAwMDAwMCwgMTI0Mi4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxjaXJjbGUgaWQ9Ik92YWwiIGZpbGw9IiMxREEzNjgiIGN4PSI4IiBjeT0iNy45OTkzMzMzMyIgcj0iNy42NjY2NjY2NyI+PC9jaXJjbGU+CiAgICAgICAgICAgICAgICA8cG9seWxpbmUgaWQ9IlBhdGgiIHN0cm9rZT0iI0ZGRkZGRiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBwb2ludHM9IjEyIDQuNjY2IDcuMzMzMzMzMzMgMTAuOTk5MzMzMyA0IDguMzMyNjY2NjciPjwvcG9seWxpbmU+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==");
                    position: absolute;
                    background-size: 1.5rem;
                    background-repeat: no-repeat;
                    background-position: center center;
                    height: 100%;
                    width: 2rem;
                    right: 1rem;
                    display: flex;
                    z-index: 10;
                    align-items: center;
                }
            }
        }

        &__single {
            opacity: 1;
            max-height: 100%;
        }

        &.selected {
            opacity: 1;
            max-height: 100%;
        }
    }
}

